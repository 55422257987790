@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--main-background: #da445f;
	--second-background: #f5f5f5;
}

body {
	background-color: #fff;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

video {
}
.first-video {
	height: 500px;
	max-height: 500px;
	min-height: 500px;
}

.whatsapp {
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.e-card {
	margin: 100px auto;
	background: transparent;
	box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
	position: relative;
	width: 240px;
	height: 330px;
	border-radius: 16px;
	overflow: hidden;
}

.wave {
	position: absolute;
	width: 540px;
	height: 700px;
	opacity: 0.6;
	left: 0;
	top: 0;
	margin-left: -50%;
	margin-top: -70%;
	background: linear-gradient(744deg, #da445f, #da445f99 60%, #e91e63e8);
}

.icon {
	width: 3em;
	margin-top: -1em;
	padding-bottom: 1em;
}

.infotop {
	text-align: center;
	font-size: 20px;
	position: absolute;
	top: 2.6em;
	left: 0;
	right: 0;
	color: rgb(255, 255, 255);
	font-weight: 600;
}

.name {
	font-size: 16px;
	font-weight: 100;
	position: relative;
	top: 1em;
	text-transform: lowercase;
}

.wave:nth-child(2),
.wave:nth-child(3) {
	top: 210px;
}

.playing .wave {
	border-radius: 40%;
	animation: wave 3000ms infinite linear;
}

.wave {
	border-radius: 40%;
	animation: wave 55s infinite linear;
}

.playing .wave:nth-child(2) {
	animation-duration: 4000ms;
}

.wave:nth-child(2) {
	animation-duration: 50s;
}

.playing .wave:nth-child(3) {
	animation-duration: 5000ms;
}

.wave:nth-child(3) {
	animation-duration: 45s;
}

@keyframes wave {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* Card Two */

.container {
	background-color: #ffffff;
	display: flex;
	width: 460px;
	height: 120px;
	position: relative;
	border-radius: 6px;
	transition: 0.3s ease-in-out;
}

.container:hover {
	transform: scale(1.03);
	width: 220px;
}

.container:hover .left-side {
	width: 100%;
}

.left-side {
	background-color: #da445f;
	width: 130px;
	height: 120px;
	border-radius: 10px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 0.3s;
	flex-shrink: 0;
	overflow: hidden;
}

.right-side {
	width: calc(100% - 130px);
	display: flex;
	align-items: center;
	overflow: hidden;
	cursor: pointer;
	justify-content: space-between;
	white-space: nowrap;
	transition: 0.3s;
}

.right-side:hover {
	background-color: #f9f7f9;
}

.arrow {
	width: 20px;
	height: 20px;
	margin-right: 20px;
}

.new {
	font-size: 23px;
	font-family: "Lexend Deca", sans-serif;
	margin-left: 20px;
}

.card {
	width: 70px;
	height: 46px;
	background-color: #f2bcc6;
	border-radius: 6px;
	position: absolute;
	display: flex;
	z-index: 10;
	flex-direction: column;
	align-items: center;
	-webkit-box-shadow: 9px 9px 9px -2px #ffffff7a;
	-moz-box-shadow: 9px 9px 9px -2px #ffffff7a;
	-webkit-box-shadow: 9px 9px 9px -2px #ffffff7a;
}

.card-line {
	width: 65px;
	height: 13px;
	background-color: #e25877;
	border-radius: 2px;
	margin-top: 7px;
}

@media only screen and (max-width: 480px) {
	.container {
		transform: scale(0.7);
	}

	.container:hover {
		transform: scale(0.74);
	}

	.new {
		font-size: 18px;
	}
}

.buttons {
	width: 8px;
	height: 8px;
	background-color: #e2667e;
	box-shadow: 0 -10px 0 0 #e2667e, 0 10px 0 0 #da445f;
	border-radius: 50%;
	margin-top: 5px;
	transform: rotate(90deg);
	margin: 10px 0 0 -30px;
}

.container:hover .card {
	animation: slide-top 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) both;
}

.container:hover .post {
	animation: slide-post 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@keyframes slide-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	50% {
		-webkit-transform: translateY(-70px) rotate(90deg);
		transform: translateY(-70px) rotate(90deg);
	}

	60% {
		-webkit-transform: translateY(-70px) rotate(90deg);
		transform: translateY(-70px) rotate(90deg);
	}

	100% {
		-webkit-transform: translateY(-8px) rotate(90deg);
		transform: translateY(-8px) rotate(90deg);
	}
}

.post {
	width: 63px;
	height: 75px;
	background-color: #dddde0;
	position: absolute;
	z-index: 11;
	bottom: 10px;
	top: 120px;
	border-radius: 6px;
	overflow: hidden;
}

.post-line {
	width: 47px;
	height: 9px;
	background-color: #545354;
	position: absolute;
	border-radius: 0px 0px 3px 3px;
	right: 8px;
	top: 8px;
}

.post-line:before {
	content: "";
	position: absolute;
	width: 47px;
	height: 9px;
	background-color: #757375;
	top: -8px;
}

.screen {
	width: 47px;
	height: 23px;
	background-color: #ffffff;
	position: absolute;
	top: 22px;
	right: 8px;
	border-radius: 3px;
}

.numbers {
	width: 12px;
	height: 12px;
	background-color: #838183;
	box-shadow: 0 -18px 0 0 #838183, 0 18px 0 0 #838183;
	border-radius: 2px;
	position: absolute;
	transform: rotate(90deg);
	left: 25px;
	top: 52px;
}

.numbers-line2 {
	width: 12px;
	height: 12px;
	background-color: #aaa9ab;
	box-shadow: 0 -18px 0 0 #aaa9ab, 0 18px 0 0 #aaa9ab;
	border-radius: 2px;
	position: absolute;
	transform: rotate(90deg);
	left: 25px;
	top: 68px;
}

@keyframes slide-post {
	50% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(-70px);
		transform: translateY(-70px);
	}
}

.dollar {
	position: absolute;
	font-size: 16px;
	font-family: "Lexend Deca", sans-serif;
	width: 100%;
	left: 0;
	top: 0;
	color: #4b953b;
	text-align: center;
}

.container:hover .dollar {
	animation: fade-in-fwd 0.3s 1s backwards;
}

@keyframes fade-in-fwd {
	0% {
		opacity: 0;
		transform: translateY(-5px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.size:hover a {
	background-color: white;
	color: var(--main-background);
	box-shadow: 1px 1px 10px 2px rgba(255, 255, 255, 0.6),
		2px 2px 20px 5px rgba(255, 255, 255, 0.3);
}
