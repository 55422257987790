.homep{

  
  text-align:justify; 
  max-width: 550px;
  max-height:150px; 
  overflow:hidden;
}

.homediv{

  max-width: 550px;
  max-height:150px; 
  
 
  
}

.h1{

  height:50px;

  overflow:hidden;

 
}